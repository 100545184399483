import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// General
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useI18n, withI18n, sessionStorageIdentifiers, LocalizationNS, useFeatureFlag, useGetToken, useGetOrganization, } from "compass-commons";
// Styles
import "./App.module.css";
import "./assets/css/global.css";
// Services
import { AuthWrapper, Button, Alert } from "dms-lib";
import { getOrgIdFromToken } from "compass-shared-services";
import StateService from "./services/StateService";
import ListenerService from "./services/ListenerService";
import UserManagerService from "./services/UserManagerService";
// Contexts
import { StateProvider } from "./contexts/StateContext";
import IntegrationManagerConfigurator from "./components/integrationManagerConfigurator/IntegrationManagerConfigurator";
// Utils
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";
const stateService = new StateService();
const { MFE_HELP_LINK_KEYWORD } = appConfig;
const listenerService = new ListenerService();
function App() {
    const token = (() => {
        const theToken = useGetToken();
        return isStandalone ? localStorage.getItem("token") : theToken;
    })();
    const orgId = getOrgIdFromToken(token);
    const organizationId = useGetOrganization();
    const isSwitchingOrg = organizationId && organizationId !== orgId;
    const isLoggedInUser = Boolean(token);
    const { alertSubject } = stateService;
    const [isAllowedUser, setIsAllowedUser] = useState();
    const { t: translate } = useI18n();
    const fetchAllowedUser = () => {
        UserManagerService.isUserAllowed().then((r) => {
            setIsAllowedUser(r);
        });
    };
    const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(appConfig, SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG);
    const handleRefresh = () => {
        window.location.reload();
    };
    const refreshAction = (_jsx(Button, Object.assign({ size: "small", color: "primary", variant: "text", onClick: handleRefresh }, { children: "Refresh" })));
    useEffect(() => {
        const handleReject = () => {
            if (!showErrorMessagesFeatureFlag)
                return;
            alertSubject.next({
                title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
                description: translate("genericErrorSubtitle", {
                    ns: LocalizationNS.SHARED,
                }),
                action: refreshAction,
            });
        };
        window.addEventListener("unhandledrejection", handleReject);
        return () => {
            window.removeEventListener("unhandledrejection", handleReject);
        };
    }, [showErrorMessagesFeatureFlag]);
    const setMainHelpLinkKeyword = () => {
        const mainContextHelpKeyword = sessionStorage.getItem(sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD);
        if (!mainContextHelpKeyword ||
            mainContextHelpKeyword !== MFE_HELP_LINK_KEYWORD) {
            sessionStorage.setItem(sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD, MFE_HELP_LINK_KEYWORD);
        }
    };
    useEffect(() => {
        setMainHelpLinkKeyword();
    }, []);
    useEffect(() => {
        if (!isLoggedInUser)
            return;
        fetchAllowedUser();
    }, [isLoggedInUser, orgId]);
    const isStandaloneMode = () => {
        return isStandalone != null && isStandalone;
    };
    useEffect(() => {
        if (isSwitchingOrg) {
            setIsAllowedUser(undefined);
        }
    }, [isSwitchingOrg]);
    return ((isLoggedInUser || isStandaloneMode()) && (_jsx(React.StrictMode, { children: _jsxs(StateProvider, Object.assign({ value: stateService }, { children: [_jsx(Helmet, Object.assign({ defer: false }, { children: _jsx("title", { children: `${translate("productNameAcronym", {
                            ns: LocalizationNS.SHARED,
                        })} | ${translate("title")}` }) })), _jsxs(AuthWrapper, Object.assign({ isAuthorized: isAllowedUser || isStandaloneMode(), isLoading: isStandaloneMode() ? false : isAllowedUser === undefined, unauthorizedTitle: translate("unauthorized", {
                        ns: LocalizationNS.SHARED,
                    }), unauthorizedDescription: translate("unauthorizedContact", {
                        ns: LocalizationNS.SHARED,
                    }) }, { children: [_jsx("div", Object.assign({ className: "integration-manager-root", "data-cr": "integration-manager-configuration-root" }, { children: _jsx(IntegrationManagerConfigurator, { listenerService: listenerService, orgId: orgId }) })), _jsx(Alert, { alertNotificationSubject: alertSubject })] }))] })) })));
}
export default withI18n(App);
